<template>
  <Header title="ContactUs" />
  <section class="inner_banner_section contact_us_banner top_margin">
    <div class="inner_banner_row">
      <!-- main_container -->
      <div class="inner_banner_col">
        <div class="inner_banner_block">
          <p class="body_heading tc">Contact Us</p>
        </div>
      </div>
    </div>
  </section>
  <!-- ---------- CONTACT US FORM SECTION ---------- -->
  <section class="contact_page_section row_padding bg_white">
    <div class="contact_page_row main_container">
      <div class="contact_page_col">
        <div class="contact_page_block">
          <div class="body_heading_block desk_heading">
            <p class="body_heading tc">
              Contact Rio Colina Exotic Riverside Villas
            </p>
            <p class="small_body_font tc">
              We love to answer your questions and help you plan your stay
            </p>
          </div>
        </div>
        <div class="contact_page_form_block">
          <div class="contact_form_container">
            <form @submit.prevent="submitForm()">
              <div class="contact_row input_row">
                <div class="contact_col">
                  <!-- <label for="fname">Name</label> -->
                  <input
                    type="text"
                    id="name"
                    v-model="form.name"
                    name="name"
                    placeholder="Name*"
                  />
                  <div v-if="errors && errors.name" class="invalid-feedback">
                    {{ errors.name[0] }}
                  </div>
                </div>

                <div class="contact_col">
                  <!-- <label for="lname">Phone*</label> -->
                  <input
                    type="number"
                    id="phone"
                    v-model="form.phone"
                    name="phone"
                    placeholder="Phone*"
                  />
                  <div v-if="errors && errors.phone" class="invalid-feedback">
                    {{ errors.phone[0] }}
                  </div>
                </div>

                <div class="contact_col">
                  <!-- <label for="lname">Email*</label> -->
                  <input
                    type="email"
                    id="email"
                    v-model="form.email"
                    name="email"
                    placeholder="Email*"
                  />
                  <div v-if="errors && errors.email" class="invalid-feedback">
                    {{ errors.email[0] }}
                  </div>
                </div>
              </div>
              <div class="contact_row mail_row">
                <div class="contact_col">
                  <!-- <label for="subject">Your message</label> -->
                  <textarea
                    id="message"
                    name="message"
                    v-model="form.message"
                    placeholder="Your message*"
                  ></textarea>
                  <div v-if="errors && errors.message" class="invalid-feedback">
                    {{ errors.message[0] }}
                  </div>
                </div>
              </div>
              <div class="contact_row btn_contact_row">
                <div class="bird bird--one">
                  <div class="contact_col">
                    <button
                      type="submit"
                      class="submitbutton"
                      :disabled="disabled"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ---------- CONTACT MAP AND DETAILS SECTION ---------- -->
  <section class="cpage_map_section row_padding bg_white">
    <div class="cpage_map_row main_container">
      <div class="cpage_map_col">
        <div class="cpage_map_block">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3717.3140932001934!2d73.05998961533491!3d21.298601883886477!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04169cc445207%3A0xac962a04a332fcd8!2sKKB%20Rio%20Colina%20-%20Club%20%26%20Villa!5e0!3m2!1sen!2sin!4v1668168436348!5m2!1sen!2sin"
            width="100%"
            height="300"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <div class="cpage_map_data_col main_footer_col main_footer_col_03">
        <div class="main_footer_block">
          <div class="main_footer_title_box">
            <p class="main_footer_title">Get in touch</p>
          </div>
          <div class="main_footer_text_box">
            <div class="cdetail_outter">
              <div class="cdetail_inner">
                <a href="tel:18008 913918">
                  <div class="cdetail_icon">
                    <i class="fa fa-phone" aria-hidden="true"></i>
                  </div>
                  <div class="cdetail_text">
                    <p class="body_font">Call 18008 913918</p>
                  </div>
                </a>
              </div>
            </div>
            <div class="cdetail_outter">
              <div class="cdetail_inner">
                <a href="mailto:receptionriocolina@gmail.com">
                  <div class="cdetail_icon">
                    <i class="fa fa-envelope-o" aria-hidden="true"></i>
                  </div>
                  <div class="cdetail_text">
                    <p class="body_font">Email: receptionriocolina@gmail.com</p>
                  </div>
                </a>
              </div>
            </div>
            <div class="cdetail_outter">
              <div class="cdetail_inner">
                <a href="https://goo.gl/maps/FCYmq9nBEQzYvh7h8" target="_blank">
                  <div class="cdetail_icon">
                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                  </div>
                  <div class="cdetail_text">
                    <p class="body_font">
                      At &amp; Post Ghala, Taluko Kamrej, Near Sugen Torrent
                      Power plant, Surat, Gujarat 394140
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="map_social_col main_footer_col main_footer_col_04">
        <div class="main_footer_block">
          <div class="main_footer_title_box">
            <p class="main_footer_title followUsText">Follow Us</p>
          </div>
          <div class="main_footer_text_box">
            <div class="social_icons">
              <a
                href="https://www.facebook.com/kkbriocolina/?ref=br_rs"
                class="round_icon"
                target="_blank"
                ><i class="fa fa-facebook-official" aria-hidden="true"></i
              ></a>
              <a
                href="https://www.instagram.com/rio_colina/"
                class="round_icon"
                target="_blank"
                ><i class="fa fa-instagram" aria-hidden="true"></i
              ></a>
              <a
                href="https://www.youtube.com/channel/UCx7JKt5zYQE7-iltaJxVgNQ/videos"
                class="round_icon"
                target="_blank"
                ><i class="fa fa-youtube-play" aria-hidden="true"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right_vector_outter_box">
      <div class="right_svg_move">
        <svg
          width="454"
          height="51"
          viewBox="0 0 454 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M453.262 32.5001C453.262 32.5001 355.762 -5.00003 274.762 28C193.762 61.0001 149.501 58.5 9.50098 9.5"
            stroke="black"
            stroke-dasharray="2 2"
          ></path>
          <path
            d="M14.5234 1.01465L1.04282 6.64648L7.57913 20.5322"
            stroke="black"
            stroke-dasharray="2 2"
          ></path>
        </svg>
      </div>
      <img :src="right_single_title" alt="" class="right_vector_animation" />
    </div>
  </section>
  <Footer />
</template>
<script>
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
import right_single_title from "../assets/images/right_single_title.png";
import axios from "axios";

export default {
  name: "ContactUs",
  components: { Header, Footer },
  data() {
    return {
      right_single_title: right_single_title,
      form: {
        name: null,
        phone: null,
        email: null,
        message: null,
      },
      disabled: false,
      errors: {},
    };
  },
  mounted() {
    const recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute("src", "./js/script.js");
    document.head.appendChild(recaptchaScript);

    window.scrollTo({ top: 0, behavior: "smooth" });
  },
  methods: {
    async submitForm() {
      this.errors = {};
      this.disabled = true;
      const url = process.env.VUE_APP_API_CUS_URL + "/enquiry-store";
      await axios
        .post(url, this.form, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.disabled = false;
          this.$toast.success(response.data.message);
          this.form = {
            name: null,
            phone: null,
            email: null,
            message: null,
          };
        })
        .catch((error) => {
          this.disabled = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors || {};
          } else if (error.response.status === 500) {
            this.$toast.error(error.data.message);
          }
        });
    },
  },
};
</script>
<style scoped>
.contact_col {
  display: block;
}

.invalid-feedback {
  color: red;
}

.submitbutton {
  display: inline-block;
  font-size: 17px;
  padding: 12px 20px;
  background: #7da741;
  color: #fff;
  border: 1px solid #000;
  margin: 0;
  display: inline-block;
  text-align: center;
  position: relative;
  overflow: hidden;
  font-weight: normal;
  cursor: pointer;
  font-weight: 500;
  transition: 1s !important;
  margin-bottom: 0px !important;
  display: flex;
  transition: 1s;
  width: fit-content;
  border-radius: 5px;
}

.submitbutton[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.contact_us_banner {
  background-position: center;
}
</style>
